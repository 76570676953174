<template>
  <div class="pt-5 pb-5">
    <div class="flex">
      <back-button
        label="Back"
        @onClick="
          $router.push({
            name: 'PerformanceSettings',
            query: { activeTab: 'PIP Criteria' },
          })
        "
        variant="secondary"
        class="mr-4"
      />
      <div
        class="mt-4"
        style="border-left: 1px solid #878e99; height: 1.5rem"
      />

      <div style="width: 230px; margin-top: 0.1rem">
        <h1 class="text-xl text-left font-extrabold ml-4 mt-3">
          Setup PIP Criteria
        </h1>
      </div>
      <div class="w-7/12" style="margin-top: 1.2rem">
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </div>

    <div
      style="height: 100%"
      class="mt-10"
      v-if="loading && this.$route.params.id"
    >
      <loader size="xxs" :loader-image="false" />
    </div>

    <div v-else>
      <div class="px-3">
        <ValidationObserver rules="required" v-slot="{ handleSubmit }">
          <card class="mt-6">
            <Alert
              message="This cannot be edited once appraisal cycle begins."
              variant="primary"
              :time="2"
              style="
                position: relative;
                right: 0;
                z-index: 0000;
                top: 0px;
                width: 70%;
                box-shadow: none;
                background: #ffffff;
              "
            />
          </card>
          <card class="mt-6 p-5">
            <div class="flex font-bold text-darkPurple text-lg flex-grow">
              Reference Template
            </div>
            <div class="w-1/3 flex flex-column mt-4">
              <c-select
                placeholder="--Select from existing template--"
                :options="templateOptions"
                variant="w-full"
                class="w-full"
                :disables="$route.params.id === 'new' ? false : true"
                label="Reference Template"
                :rules="['required']"
                v-model="pipSettingsPayload.templateId"
              />
            </div>
          </card>
          <card class="mt-4 px-5">
            <div class="flex flex-col">
              <div class="w-full text-darkPurple font-semibold text-lg my-5">
                Performance Improvement Plan
              </div>
              <div class="w-full flex">
                <p class="text-base text-jet mr-3">
                  Initiate PIP process for an employee if Score is:
                </p>
                <radio-button
                  :options="category"
                  col-span="col-span-12"
                  class="-mt-2 text-darkPurple"
                  v-model="pipSettingsPayload.pip.score_slug"
                />
                <c-text
                  placeholder="0"
                  variant="w-full"
                  class="ml-6 -mt-2 mb-5 w-32"
                  type="number"
                  label=""
                  :max="`${highest_rating}`"
                  :min="`${lowest_rating}`"
                  v-model="pipSettingsPayload.pip.employeeScore"
                  :rules="checkScore"
                />
              </div>
              <div
                class="flex flex-col p-4 border mb-4 border-dashed w-3/5 mt-6"
                style="border-color: #878e99"
              >
                <p class="text-base text-jet">PIP Outcome</p>
                <div class="w-full flex">
                  <c-text
                    placeholder="Criteria (%)"
                    variant="w-full"
                    style="margin-bottom: 18px"
                    label="Success Criteria (Post PIP) %"
                    class="mt-4 w-1/3 mr-44"
                    v-model="pipSettingsPayload.pip.pipOutcome"
                    :rules="['required']"
                    type="number"
                  />
                  <c-select
                    placeholder="Select Action"
                    :options="pipOptions"
                    variant="w-full"
                    style="margin-bottom: 18px"
                    label="Unsuccessful Outcome Action"
                    class="mt-4 w-2/4"
                    v-model="pipSettingsPayload.pip.outcomeActionId"
                    :rules="['required']"
                  />
                </div>
              </div>
            </div>
          </card>
          <card class="mt-4 px-5">
            <div class="flex flex-col" style="border-color: #878e99">
              <div class="w-full text-darkPurple font-semibold text-lg my-5">
                PIP Performance Review
              </div>
              <div class="flex flex-col w-3/5">
                <p class="-mb-2 text-base">
                  Select Preferred Number of Review Sessions
                </p>
                <c-text
                  placeholder="Enter value"
                  variant="w-full"
                  style="margin-bottom: 18px"
                  type="number"
                  class="mt-4 w-1/3"
                  v-model="reviewCount"
                />
                <p class="text-xs text-romanSilver mb-5">
                  This number determines how many rows will show in the next
                  table
                </p>
              </div>
            </div>
            <div
              class="flex mb-5 flex-col border-t border-solid"
              v-if="pipSettingsPayload.performanceReview.length > 0"
            >
              <div class="mt-5 mb-3 flex">
                <div
                  class="w-1/5 text-xs ml-6 text-romanSilver font-black uppercase"
                >
                  Review Session
                </div>
                <div
                  class="w-1/4 text-xs text-romanSilver font-black uppercase"
                >
                  Relative Weight (%)
                </div>
              </div>
              <div
                class="mt-2 flex"
                v-for="(review, index) in pipSettingsPayload.performanceReview"
                :key="index"
              >
                <div class="w-1/5 text-xs mt-3 ml-6 font-bold pl-8">
                  {{ review.reviewSession }}
                </div>
                <div
                  class="w-1/4 text-xs text-romanSilver font-black uppercase"
                >
                  <ValidationObserver>
                    <c-text
                      placeholder="Weight"
                      variant="w-full"
                      class="w-3/4 -mt-1 mb-5 w-label"
                      type="number"
                      v-model="review.weight"
                      label="Weight Value"
                      :rules="checkWeight"
                    />
                  </ValidationObserver>
                </div>
              </div>
            </div>
          </card>
          <card class="mt-4 px-5">
            <div class="flex flex-col">
              <div class="w-full text-darkPurple font-semibold text-lg mt-5">
                Signatories
              </div>
              <div class="w-full text-jet text-base my-6">
                Add signatories for each PIP stage with the given dropdowns.
                Employeess/Appraisees and their line managers and HR Admin are
                mandatory signatories.
              </div>
              <div class="flex">
                <div
                  class="flex flex-col p-3 border border-dashed w-1/3 mr-3"
                  style="border-color: #878e99"
                >
                  <c-select
                    placeholder="--Select Signatory(s)--"
                    variant="w-full"
                    label="Performance Improvement Plan"
                    :options="designations"
                    v-model="performanceImprovement"
                    :rules="['required']"
                  />
                  <div
                    class="mt-3 py-2 px-2 mb-24 text-xs font-semibold uppercase text-romanSilver text-center"
                    v-if="selectedImprovementTags.length === 0"
                  >
                    None Selected
                  </div>
                  <div class="mt-3 py-1" v-else>
                    <MultiSelectTag
                      bg-color="#F7F7FF"
                      size="xms"
                      v-for="(improvement, index) in selectedImprovementTags"
                      :key="index"
                      class="w-full"
                      @close="deleteSelectedImprovement(improvement.id)"
                    >
                      <div
                        class="text-jet flex flex-grow"
                        style="font-size: 14px; margin-right: 10px"
                      >
                        {{ improvement.name }}
                      </div>
                    </MultiSelectTag>
                  </div>
                </div>
                <div
                  class="flex flex-col p-4 border border-dashed w-1/3 mr-3"
                  style="border-color: #878e99"
                >
                  <c-select
                    placeholder="--Select Signatory(s)--"
                    variant="w-full"
                    label="PIP Performance Review"
                    :options="designations"
                    v-model="performanceReview"
                    :rules="['required']"
                  />
                  <div
                    class="mt-3 py-2 px-2 mb-24 text-xs font-semibold uppercase text-romanSilver text-center"
                    v-if="selectedReviewTags.length === 0"
                  >
                    None Selected
                  </div>
                  <div class="mt-3 py-1" v-else>
                    <MultiSelectTag
                      bg-color="#F7F7FF"
                      size="xms"
                      v-for="(review, index) in selectedReviewTags"
                      :key="index"
                      class="w-full"
                      @close="deleteSelectedReview(review.id)"
                    >
                      <div
                        class="text-jet flex flex-grow"
                        style="font-size: 14px; margin-right: 10px"
                      >
                        {{ review.name }}
                      </div>
                    </MultiSelectTag>
                  </div>
                </div>
                <div
                  class="flex flex-col p-4 border border-dashed w-1/3"
                  style="border-color: #878e99"
                >
                  <c-select
                    placeholder="--Select Signatory(s)--"
                    variant="w-full"
                    label="PIP Closeout Session"
                    :options="designations"
                    v-model="performanceCloseout"
                    :rules="['required']"
                  />
                  <div
                    class="mt-3 py-2 px-2 mb-24 text-xs font-semibold uppercase text-romanSilver text-center"
                    v-if="selectedCloseoutTags.length === 0"
                  >
                    None Selected
                  </div>
                  <div class="mt-3 py-1" v-else>
                    <MultiSelectTag
                      bg-color="#F7F7FF"
                      size="xms"
                      v-for="(closeOut, index) in selectedCloseoutTags"
                      :key="index"
                      class="w-full"
                      @close="deleteSelectedCloseout(closeOut.id)"
                    >
                      <div
                        class="text-jet flex flex-grow"
                        style="font-size: 14px; margin-right: 10px"
                      >
                        {{ closeOut.name }}
                      </div>
                    </MultiSelectTag>
                  </div>
                </div>
              </div>
              <div class="pl-3 mt-7 mb-5 flex flex-row">
                <div class="flex flex-grow">
                  <Button
                    class="bg-dynamicBackBtn text-white"
                    width="126px"
                    @click="handleSubmit(organizePayload)"
                  >
                    Save
                  </Button>
                  <Button
                    class="text-darkPurple ml-4 cursor-pointer"
                    width="126px"
                    @click="
                      $router.push({
                        name: 'PerformanceSettings',
                        query: { activeTab: 'PIP Criteria' },
                      })
                    "
                  >
                    Cancel
                  </Button>
                </div>

                <div class="flex justify-end">
                  <Button
                    class="text-dynamicBackBtn border border-solid border-dynamicBackBtn text-center"
                    width="8rem"
                    @click="organizePayload('draft')"
                    v-if="
                      pipSettingsPayload.status === 'draft' &&
                      pipSettingsPayload.templateId !== ''
                    "
                  >
                    Save Draft
                  </Button>
                </div>
              </div>
            </div>
          </card>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import Alert from "@scelloo/cloudenly-ui/src/components/alert";
import Card from "@/components/Card";
import RadioButton from "@/components/RadioButton";
import Button from "@/components/Button";
import MultiSelectTag from "@/components/MultiSelectTag";

export default {
  name: "EditPIPCriteria",
  components: {
    ValidationObserver,
    BackButton,
    Breadcrumb,
    CSelect,
    CText,
    Alert,
    Card,
    RadioButton,
    Button,
    MultiSelectTag,
  },
  data() {
    return {
      breadcrumbs: [
        {
          disabled: false,
          text: "Settings",
          href: "settings",
          id: "Settings",
        },
        {
          disabled: false,
          text: "Performance",
          href: "performance",
          id: "Performance",
        },
        {
          disabled: false,
          text: "Improvement Criteria",
          id: "Improvement Criteria",
        },
        {
          disabled: false,
          text: "Setup PIP Criteria",
          id: "Setup PIP Criteria",
        },
      ],
      category: [
        { name: "Less Than", radioName: "score", value: "less_than" },
        {
          name: "Less Than OR Equal To",
          radioName: "score",
          value: "less_or_equal",
        },
      ],
      selectedtags: [
        { tags: "Divisional Executive" },
        { tags: "HR Performance Specialist" },
        { tags: "HR Manager" },
      ],
      loading: false,
      pipOptions: [],
      templateOptions: [],
      designations: [],
      performanceImprovement: "",
      performanceReview: "",
      performanceCloseout: "",
      selectedImprovementTags: [],
      selectedReviewTags: [],
      selectedCloseoutTags: [],
      reviewCount: "",
      reviewWeight: [],
      Ratings: [],
      lowest_rating: "",
      highest_rating: "",
      checkScore: [
        "required",
        {
          name: "value",
          rule: (l) => this.validateScore(l),
        },
      ],
      checkWeight: [
        "required",
        {
          name: "value",
          rule: (l) => this.validateWeight(l),
        },
      ],
      pipSettingsPayload: {
        templateId: "",
        orgId: "",
        status: "",
        pip: {
          score_slug: "less_than",
          employeeScore: null,
          pipOutcome: null,
          outcomeActionId: "",
        },
        performanceReview: [],
        signatory: {
          pipSignatories: [],
          pipReviewSignatories: [],
          closeoutSessionignatories: [],
        },
      },
      usedTemplates: [],
    };
  },

  watch: {
    reviewCount(value) {
      this.pipSettingsPayload.performanceReview = [];
      if (!this.$route.params.id) {
        for (let i = 0; i < value; i++) {
          this.pipSettingsPayload.performanceReview.push({
            reviewSession: i + 1,
            weight: "",
          });
        }
      } else if (this.$route.params.id) {
        const weightValues = this.reviewWeight.toString().split(",");
        for (let i = 0; i < Number(value); i++) {
          this.pipSettingsPayload.performanceReview.push({
            reviewSession: i + 1,
            weight: weightValues[i],
          });
        }
      }
    },

    performanceImprovement() {
      this.designations.filter((designation) => {
        if (designation.id === this.performanceImprovement) {
          this.selectedImprovementTags.push({
            id: designation.id,
            name: designation.name,
          });
        }
        return {};
      });

      const disableIndex = this.designations
        .map((item) => item.id)
        .indexOf(this.performanceImprovement);

      document
        .querySelectorAll("select")[2]
        .options[disableIndex + 1].setAttribute("disabled", true);
    },

    performanceReview() {
      this.designations.filter((designation) => {
        if (designation.id === this.performanceReview) {
          this.selectedReviewTags.push({
            id: designation.id,
            name: designation.name,
          });
        }
        return {};
      });

      const disableIndex = this.designations
        .map((item) => item.id)
        .indexOf(this.performanceReview);

      document
        .querySelectorAll("select")[3]
        .options[disableIndex + 1].setAttribute("disabled", true);
    },

    performanceCloseout() {
      this.designations.filter((designation) => {
        if (designation.id === this.performanceCloseout) {
          this.selectedCloseoutTags.push({
            id: designation.id,
            name: designation.name,
          });
        }
        return {};
      });

      const disableIndex = this.designations
        .map((item) => item.id)
        .indexOf(this.performanceCloseout);

      document
        .querySelectorAll("select")[4]
        .options[disableIndex + 1].setAttribute("disabled", true);
    },
  },

  methods: {
    validateScore(value) {
      if (value < this.lowest_rating) {
        return `Score must not be lower than ${this.lowest_rating}`;
      }

      if (value > this.highest_rating) {
        return `Score must not be higher than ${this.highest_rating}`;
      }

      return true;
    },

    validateWeight() {
      let sum = 0;

      this.pipSettingsPayload.performanceReview.map((review) => {
        sum += Number(review.weight);
        return {};
      });

      if (sum > 100) {
        return "Exceeded Total weight of 100%";
      }

      return true;
    },

    getDesignations() {
      this.$_getDesignations("").then((response) => {
        response.data.designations.forEach((designation) => {
          this.designations.push({
            id: designation.id,
            name: designation.name,
          });
          return {};
        });
      });
    },

    deleteSelectedImprovement(id) {
      const optionsIndex = this.designations.map((item) => item.id).indexOf(id);
      const selectedIndex = this.selectedImprovementTags
        .map((item) => item.id)
        .indexOf(id);

      this.selectedImprovementTags.splice(selectedIndex, 1);
      document
        .querySelectorAll("select")[2]
        .options[optionsIndex + 1].removeAttribute("disabled");

      if (this.selectedImprovementTags.length === 0) {
        this.performanceImprovement = "";
      }
    },

    deleteSelectedReview(id) {
      const optionsIndex = this.designations.map((item) => item.id).indexOf(id);
      const selectedIndex = this.selectedReviewTags
        .map((item) => item.id)
        .indexOf(id);

      this.selectedReviewTags.splice(selectedIndex, 1);
      document
        .querySelectorAll("select")[3]
        .options[optionsIndex + 1].removeAttribute("disabled");

      if (this.selectedReviewTags.length === 0) {
        this.performanceReview = "";
      }
    },

    deleteSelectedCloseout(id) {
      const optionsIndex = this.designations.map((item) => item.id).indexOf(id);
      const selectedIndex = this.selectedCloseoutTags
        .map((item) => item.id)
        .indexOf(id);

      this.selectedCloseoutTags.splice(selectedIndex, 1);
      document
        .querySelectorAll("select")[4]
        .options[optionsIndex + 1].removeAttribute("disabled");

      if (this.selectedCloseoutTags.length === 0) {
        this.performanceCloseout = "";
      }
    },

    getTemplates() {
      let templatesArr = [];

      this.$_getPerfomanceTemplate('').then((response) => {
        templatesArr = response.data.PerformanceTemplate;

        templatesArr.filter((template) => {
          if (template.template_type !== "draft") {
            this.templateOptions.push({
              id: template.id,
              name: template.title,
            });
          }
          return {};
        });

        this.loading = false;
      });
      this.timer = setTimeout(() => {
        this.disableTemplates();
      }, 1000);
    },

    disableTemplates() {
      this.templateOptions.filter((template, index) => {
        if (this.usedTemplates.includes(template.id)) {
          document
            .querySelectorAll("select")[0]
            .options[index + 1].setAttribute("disabled", true);
        }

        return {};
      });
    },

    getPIPOutcome() {
      this.$_getPIPOutcome().then((response) => {
        const pipOutcome = response.data.outcome;
        pipOutcome.forEach((outcome) => {
          this.pipOptions.push({
            id: outcome.id,
            name: outcome.type,
          });
        });
      });
    },

    organizePayload(status) {
      this.pipSettingsPayload.signatory.pipSignatories = [];
      this.pipSettingsPayload.signatory.pipReviewSignatories = [];
      this.pipSettingsPayload.signatory.closeoutSessionignatories = [];

      if (status !== "draft") {
        this.pipSettingsPayload.status = "active";
      }
      this.pipSettingsPayload.orgId = this.$orgId;

      this.pipSettingsPayload.performanceReview.forEach((review) => {
        const reviewValue = review;
        reviewValue.weight = Number(review.weight);
      });

      const score = Number(this.pipSettingsPayload.pip.employeeScore);
      const outcomeValue = Number(this.pipSettingsPayload.pip.pipOutcome);

      this.pipSettingsPayload.pip.employeeScore = score;
      this.pipSettingsPayload.pip.pipOutcome = outcomeValue;

      this.selectedImprovementTags.map((selected) => {
        this.pipSettingsPayload.signatory.pipSignatories.push(selected.id);
        return {};
      });

      this.selectedReviewTags.map((selected) => {
        this.pipSettingsPayload.signatory.pipReviewSignatories.push(
          selected.id
        );
        return {};
      });

      this.selectedCloseoutTags.map((selected) => {
        this.pipSettingsPayload.signatory.closeoutSessionignatories.push(
          selected.id
        );
        return {};
      });

      if (status !== "draft") {
        this.pipSettingsPayload.status = "active";
      } else {
        this.pipSettingsPayload.status = "draft";
      }

      this.savePIPSettings();
    },

    savePIPSettings() {
      this.$_createPIPSetting(this.pipSettingsPayload)
        .then(() => {
          this.$toasted.success("PIP outcome action created successfully", {
            duration: 5000,
          });
          this.$router.push({
            name: "PerformanceSettings",
            query: { activeTab: "PIP Criteria" },
          });
        })
        .catch((error) => {
          this.$toasted.error(error.response.data.message, { duration: 5000 });
        });
    },

    displayPIPSetting(pipData) {
      this.pipSettingsPayload.pipId = pipData.id;
      this.pipSettingsPayload.templateId = pipData.templateId;
      this.pipSettingsPayload.pip.score_slug = pipData.score_slug;
      this.pipSettingsPayload.pip.employeeScore = pipData.employeeScore;
      this.pipSettingsPayload.pip.pipOutcome = pipData.pipOutcome;
      this.pipSettingsPayload.pip.outcomeActionId = pipData.outcomeActionId;
      pipData.PerformanceReviewSessions.map((session) => {
        this.reviewWeight.push(session.weight);
        return {};
      });

      this.reviewCount = pipData.PerformanceReviewSessions.length;

      pipData.pipSignatories.map((signatories) => {
        this.timer = setTimeout(() => {
          this.performanceImprovement = signatories;
        }, 1000);
        return {};
      });

      pipData.pipReviewSignatories.map((signatories) => {
        this.timer = setTimeout(() => {
          this.performanceReview = signatories;
        }, 1000);
        return {};
      });

      pipData.closeoutSessionignatories.map((signatories) => {
        this.timer = setTimeout(() => {
          this.performanceCloseout = signatories;
        }, 1000);
        return {};
      });
    },

    getPIPSettings() {
      this.$_getPIPSetting().then((response) => {
        const pipCriteria = response.data.plans;

        pipCriteria.forEach((criteria) => {
          this.usedTemplates.push(criteria.templateId);
        });

        this.getTemplates();
      });
    },

    async getPerfomanceRating() {
      try {
        const getPerfomanceRating = await this.$_getPerfomanceRating();
        this.Ratings = getPerfomanceRating.data.PerformanceRatings;

        const sortedRatings = this.Ratings.sort((a, b) => a.rating - b.rating);

        this.Ratings = sortedRatings;

        this.lowest_rating = Math.min.apply(
          this.Ratings,
          this.Ratings.map((item) => item.rating)
        );
        this.highest_rating = Math.max.apply(
          this.Ratings,
          this.Ratings.map((item) => item.rating)
        );
      } catch (err) {
        throw new Error(err);
      }
    },
  },

  mounted() {
    this.getPIPSettings();
    this.getPIPOutcome();
    this.getDesignations();
    if (this.$route.params.id) {
      this.loading = true;
      this.displayPIPSetting(this.$route.params.id);
    }
    this.getPerfomanceRating();
  },
};
</script>

<style>
.label label,
.w-label label {
  display: none;
}

.label small {
  display: none;
}
</style>
