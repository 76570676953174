<template>
  <div
    class="flex h-auto mb-4 tag-container min-w-max"
    :class="className"
    :style="`background-color:${bgColor};`"
  >
    <slot />
    <div
      class="flex ml-4 cursor-pointer"
      style="margin-right: auto;"
      @click="$emit('close')"
      v-if="close"
    >
      <icon
        icon-name="close-icon"
        :size="`${size}`"
        class="-mt-1"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "MultiSelectTag",
  props: {
    bgColor: {
      type: String,
      default: 'none',
    },
    size: {
      type: String,
      default: 's'
    },
    className: {
      type: String,
      default: 'w-auto',
    },
    close: {
      type: Boolean,
      default: true
    }
  },
};
</script>

<style scoped>
 .tag-container
 {
     padding: 7px 8px;
     border-radius: 5px;
     margin-right: 13px;
 }
</style>
